"use client";

import React, { createContext, useContext, useState, ReactNode } from "react";
import { Snackbar, Alert } from "@mui/material";
type AlertContextType = {
  showAlert: (message: string, severity?: "success" | "info" | "warning" | "error") => void;
};
const AlertContext = createContext<AlertContextType | undefined>(undefined);
export const AlertProvider: React.FC<{
  children: ReactNode;
}> = ({
  children
}) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "info" | "warning" | "error">("info");
  const showAlert = (msg: string, sev: "success" | "info" | "warning" | "error" = "info") => {
    setMessage(msg);
    setSeverity(sev);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  return <AlertContext.Provider value={{
    showAlert
  }} data-sentry-element="unknown" data-sentry-component="AlertProvider" data-sentry-source-file="AlertContext.tsx">
      {children}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{
      vertical: "top",
      horizontal: "center"
    }} data-sentry-element="Snackbar" data-sentry-source-file="AlertContext.tsx">
        <Alert onClose={handleClose} severity={severity} data-sentry-element="Alert" data-sentry-source-file="AlertContext.tsx">
          {message}
        </Alert>
      </Snackbar>
    </AlertContext.Provider>;
};
export const useAlert = () => {
  const context = useContext(AlertContext);
  if (context === undefined) {
    throw new Error("useAlert must be used within an AlertProvider");
  }
  return context;
};