import { useForm } from "react-hook-form";

import { isClerkAPIResponseError, useSignIn } from "@clerk/nextjs";
import { useRouter } from "next/navigation";

import { useAlert } from "@components/context/AlertContext";
import { useLoading } from "@components/context/LoadingContext";
import { SignInInputs } from "@components/ui-parts/form/SignInForm";

const useSignInForm = (defaultValues: SignInInputs) => {
  const { setLoading } = useLoading();
  const { isLoaded, signIn, setActive } = useSignIn();
  const router = useRouter();
  const { showAlert } = useAlert();

  const signInFormMethods = useForm<SignInInputs>({ defaultValues });

  const handleSignInFormSubmit = async (data: SignInInputs) => {
    if (!isLoaded) return;
    setLoading(true);
    try {
      const result = await signIn.create({
        identifier: data.email,
        password: data.password,
      });

      if (result.status === "complete") {
        showAlert("ログインしました。", "success");
        await setActive({ session: result.createdSessionId });
        if (
          !defaultValues.redirectUrl ||
          defaultValues.redirectUrl.match(/^\/sign-.*/)
        ) {
          router.push("/");
        } else {
          router.push(defaultValues.redirectUrl);
        }
      } else {
        console.log(result);
      }
    } catch (err) {
      setLoading(false);
      console.error(err);

      if (isClerkAPIResponseError(err)) {
        if (err.errors[0].code === "form_password_incorrect") {
          showAlert(
            "パスワードが正しくありません。再試行するか、別の方法を使用してください。",
            "error",
          );
        } else {
          showAlert(
            "申し訳ありませんが、問題が発生しました。再度お試しください。",
            "error",
          );
        }
      }
    }
  };

  return {
    signInFormMethods,
    handleSignInFormSubmit,
  };
};

export default useSignInForm;
