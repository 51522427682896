"use client";

import * as React from "react";
import { FormProvider, UseFormReturn } from "react-hook-form";
import Button from "../Button";
import FormWrapper from "./parts/FormWrapper";
import TextField from "./parts/TextField";
export interface SignInInputs {
  email: string;
  password: string;
  redirectUrl?: string;
}
interface SignInFormProps {
  onSubmit: (data: SignInInputs) => void;
  formMethods: UseFormReturn<SignInInputs>;
  loading: boolean;
}
const SignInForm: React.FC<SignInFormProps> = ({
  onSubmit,
  formMethods,
  loading
}) => {
  const {
    register,
    formState: {
      isValid
    }
  } = formMethods;
  return <FormProvider {...formMethods} data-sentry-element="FormProvider" data-sentry-component="SignInForm" data-sentry-source-file="SignInForm.tsx">
      <form onSubmit={formMethods.handleSubmit(onSubmit)} style={{
      width: "100%"
    }}>
        <FormWrapper data-sentry-element="FormWrapper" data-sentry-source-file="SignInForm.tsx">
          <TextField {...register("email", {
          required: "メールアドレスを入力してください",
          pattern: {
            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            message: "正しいメールアドレスを入力してください"
          }
        })} label="ログインID（メールアドレス）" disabled={loading} data-sentry-element="TextField" data-sentry-source-file="SignInForm.tsx" />
          <TextField {...register("password", {
          required: "パスワードを入力してください"
        })} label="パスワード" disabled={loading} type="password" data-sentry-element="TextField" data-sentry-source-file="SignInForm.tsx" />
          <Button type="submit" label="ログイン" disabled={!isValid || loading} loading={loading} data-sentry-element="Button" data-sentry-source-file="SignInForm.tsx" />
        </FormWrapper>
      </form>
    </FormProvider>;
};
export default SignInForm;