"use client";

import { useSearchParams } from "next/navigation";
import SignIn from "@components/page/SignIn";
export default function Page() {
  const searchParams = useSearchParams();
  const email = searchParams.get("email") || "";
  const password = searchParams.get("password") || "";
  const redirectUrl = searchParams.get("redirect_url") || "";
  const queryParams = {
    email,
    password,
    redirectUrl
  };
  return <SignIn queryParams={queryParams} data-sentry-element="SignIn" data-sentry-component="Page" data-sentry-source-file="page.tsx" />;
}