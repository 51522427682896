import React from "react";
import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { SxProps } from "@mui/material";
interface ButtonProps extends LoadingButtonProps {
  label?: string;
  icon?: React.ReactNode;
  sx?: SxProps;
}
const Button: React.FC<ButtonProps> = ({
  label,
  icon,
  sx,
  color = "primary",
  ...props
}) => {
  return <LoadingButton variant="contained" color={color} startIcon={icon} {...props} sx={sx} data-sentry-element="LoadingButton" data-sentry-component="Button" data-sentry-source-file="Button.tsx">
      {label}
    </LoadingButton>;
};
export default Button;