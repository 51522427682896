import { useEffect } from "react";
import { useClerk, useSession } from "@clerk/nextjs";
import { Box, Card, CardActions, CardContent, Container } from "@mui/material";
import Image from "next/image";
import { useLoading } from "@components/context/LoadingContext";
import SignInForm from "@components/ui-parts/form/SignInForm";
import useSignInForm from "@hooks/form/useSignInForm";
interface SignInProps {
  queryParams: {
    email: string;
    password: string;
    redirectUrl: string;
  };
}
const SignIn: React.FC<SignInProps> = ({
  queryParams
}) => {
  const {
    isLoading
  } = useLoading();
  const {
    signInFormMethods,
    handleSignInFormSubmit
  } = useSignInForm(queryParams);
  const {
    signOut
  } = useClerk();
  const {
    isLoaded,
    isSignedIn
  } = useSession();
  useEffect(() => {
    if (isLoaded && isSignedIn && !isLoading) {
      signOut();
    }
  }, [isLoaded, isSignedIn]);
  return <Box component="main" sx={{
    display: "flex"
  }} data-sentry-element="Box" data-sentry-component="SignIn" data-sentry-source-file="SignIn.tsx">
      <Container sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh"
    }} data-sentry-element="Container" data-sentry-source-file="SignIn.tsx">
        <Card variant="outlined" sx={{
        width: "100%",
        maxWidth: 400,
        minWidth: 300,
        p: 2
      }} data-sentry-element="Card" data-sentry-source-file="SignIn.tsx">
          <CardContent data-sentry-element="CardContent" data-sentry-source-file="SignIn.tsx">
            <Image src="/logo.png" width={295} height={30} alt="YASUDA" style={{
            width: "100%",
            height: "auto"
          }} data-sentry-element="Image" data-sentry-source-file="SignIn.tsx" />
          </CardContent>
          <CardActions data-sentry-element="CardActions" data-sentry-source-file="SignIn.tsx">
            <SignInForm onSubmit={handleSignInFormSubmit} formMethods={signInFormMethods} loading={isLoading} data-sentry-element="SignInForm" data-sentry-source-file="SignIn.tsx" />
          </CardActions>
        </Card>
      </Container>
    </Box>;
};
export default SignIn;