import React from "react";
import { useFormContext } from "react-hook-form";
import { SxProps, Theme } from "@mui/material";
import MuiTextField from "@mui/material/TextField";
interface TextFieldProps {
  name: string;
  label?: string;
  type?: string;
  disabled?: boolean;
  rules?: object;
  sx?: SxProps<Theme>;
}
const TextField: React.FC<TextFieldProps> = ({
  name,
  label,
  type = "text",
  disabled,
  rules,
  sx
}) => {
  const {
    register,
    formState: {
      errors
    }
  } = useFormContext();
  return <MuiTextField {...register(name, rules)} fullWidth label={label || name} type={type} disabled={disabled} size="small" error={!!errors[name]} helperText={errors[name]?.message as string} sx={sx} data-sentry-element="MuiTextField" data-sentry-component="TextField" data-sentry-source-file="TextField.tsx" />;
};
export default TextField;