"use client";

import React, { createContext, useContext, useState, ReactNode, useMemo } from "react";
type LoadingContextType = {
  isLoading: boolean;
  setLoading: (isLoading: boolean) => void;
};
const LoadingContext = createContext<LoadingContextType>({
  isLoading: false,
  setLoading: () => {}
});
export const useLoading = () => useContext(LoadingContext);
export const LoadingProvider: React.FC<{
  children: ReactNode;
}> = ({
  children
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const setLoading = (loading: boolean) => {
    setIsLoading(loading);
  };
  const value = useMemo(() => ({
    isLoading,
    setLoading
  }), [isLoading]);
  return <LoadingContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="LoadingProvider" data-sentry-source-file="LoadingContext.tsx">{children}</LoadingContext.Provider>;
};