import React from "react";
import { Box, SxProps } from "@mui/material";
interface FormWrapperProps {
  children: React.ReactNode;
  sx?: SxProps;
}
const FormWrapper: React.FC<FormWrapperProps> = ({
  children
}) => {
  return <Box display="flex" flexDirection="column" gap={2} data-sentry-element="Box" data-sentry-component="FormWrapper" data-sentry-source-file="FormWrapper.tsx">
      {children}
    </Box>;
};
export default FormWrapper;